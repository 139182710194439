<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-14 10:28:24
 * @LastEditTime: 2022-11-09 11:32:18
-->
<template>
  <div class="home" style="padding-bottom: 3rem">
    <!-- 轮播 -->
    <div class="main box">
      <div class="select">
        <div class="img-position">
          <img :src="`${publickPath}static/img/photo/position.png`" />
        </div>
        <van-dropdown-menu v-if="addRessValue">
          <van-dropdown-item v-model="addRessValue" :options="addRessOption" @change="addRessChange" />
        </van-dropdown-menu>
        <div class="img-arrow">
          <div @click="getLocationNow" v-if="
            returnRoleData.xiaoqu.length == 0 &&
            returnRoleData.danwei.length == 0 &&
            !showLoading
          ">
            重新定位
          </div>
          <van-loading type="spinner" color="#1989fa" size="20" v-if="showLoading" />
          <div class="imgRight" v-if="returnRoleData.xiaoqu.length != 0">
            <img :src="`${publickPath}static/img/photo/arrow-right.png`" />
          </div>
        </div>
      </div>

      <!-- 轮播图 -->
      <div class="home-swiper">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, i) in carouselImgList" :key="i" style="position: relative">
            <wx-open-launch-weapp style="baoliao" class="advertising-box" :id="miniId" :username="miniId"
              v-if="item.jumpLink" :path="item.jumpLink">
              <script type="text/wxtag-template">
                <style>
                  .text {
                    width: 41.875rem;
                    height: 10.5rem;
                    background-color:red;
                  }
                </style>
                <div class="text">
                </div>
              </script>
            </wx-open-launch-weapp>
            <img style="width: 100%; height:9.875rem; display: block; border-radius: 0.3125rem"
              :src="item.carouselUrl || ''" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- <a href="https://hydd.youlove.com.cn/wx?id=1563103815555637250"
        >11111111</a
      > -->
      <!-- 好用点点推荐 -->
      <div class="codes">
        <div class="title">好用点点推荐</div>
        <div class="content">
          <wx-open-launch-weapp style="baoliao" class="content-left" :id="miniId" :username="miniId"
            path="/pages/authorization?path=/pagesA/charge/index">
            <script type="text/wxtag-template">
              <style>
                 .text {
                    font-size: 1.1875rem;
                    font-weight: 500;
                    color: #ffffff;
                  }
                  .copyWriting {
                    margin-top: .5625rem;
                    font-size: .875rem;
                    font-weight: 400;
                    color: #ffffff;
                  }
                  .circle {
                    margin-top: .5625rem;
                    width: 3.4375rem;
                    height: 1.5362rem;
                    background: #ffffff;
                    border-radius: 0.75rem;
                    font-size: 0.875rem;
                    font-weight: 500;
                    color: #41a4ff;
                    text-align: center;
                    line-height: 1.5362rem;
                  }
                </style>
               <div>
                  <div class="text">附近充电桩</div>
                  <div class="copyWriting">新能源、新生活</div>
                  <div class="circle">去充电</div>
                  </div>
            </script>
          </wx-open-launch-weapp>

          <div class="content-right">
            <div class="right-top">
              <wx-open-launch-weapp style="baoliao" :id="miniId" :username="miniId"
                path="pages/authorization?path=/pagesA/cz/phone">
                <script type="text/wxtag-template">
                  <style>
                    .text {
                      position: relative;
                      font-size: 1.1875rem;
                      line-height: 1.1875rem;
                      font-weight: 500;
                      color: #983434;
                    }
                    .discount {
                      position: absolute;
                      top: 50%;
                      left: 5rem;
                      transform: translateY(-50%);
                      width: 2.5rem;
                      height: 1.1875rem;
                      background: #ff4848;
                      border-radius: 0.625rem;
                      font-size: 0.875rem;
                      font-weight: 500;
                      color: #ffffff;
                      text-align: center;
                    }
                     .copyWriting {
                      margin-top: .5625rem;
                      font-size: .875rem;
                      font-weight: 400;
                      color: #983434;
                    }
                    .circle {
                      margin-top: .5625rem;
                      width: 3.4375rem;
                      height: 1.5362rem;
                      background: #ffffff;
                      border-radius: 0.75rem;
                      font-size: 0.875rem;
                      font-weight: 500;
                      text-align: center;
                      line-height: 1.5362rem;
                      color: #983434;
                    }
                  </style>
                  <div>
                    <div class="text">
                    话费充值
                    <div style="opacity: {{ zk }}" class="discount">{{ zk }}折</div>
                    </div>
                    <div class="copyWriting">超低折扣全网任意充</div>
                    <div class="circle">去充值</div>
                  </div>
                </script>
              </wx-open-launch-weapp>
            </div>
            <div class="right-bottom">
              <div class="bottomBox1">
                <wx-open-launch-weapp style="baoliao" :id="miniId" :username="miniId" :path="txmurl">
                  <script type="text/wxtag-template">
                    <style>
                      .text {
                          font-size: 1rem;
                          font-weight: 500;
                          color: #d29555;
                        }
                        .copyWriting {
                          margin-top: 0.25rem;
                          font-size: 0.75rem;
                          font-weight: 400;
                          color: #ce9559;
                        }
                        .circle {
                          margin-top: 0.25rem;
                          width: 2.5rem;
                          height: 1.1875rem;
                          background: #ffffff;
                          border-radius: 0.625rem;
                          font-size: 0.6875rem;
                          font-weight: 500;
                          color: #ffae5a;
                          text-align: center;
                          line-height: 1.1875rem;
                        }
                    </style>
                     <div >
                        <div class="text">通行码</div>
                        <div class="copyWriting">一码无感畅行</div>
                        <div class="circle">去查看</div>
                      </div>
                  </script>
                </wx-open-launch-weapp>
              </div>
              <div class="bottomBox2">
                <wx-open-launch-weapp style="baoliao" :id="miniId" :username="miniId"
                  path="pages/authorization?path=my/visitorYq/index">
                  <script type="text/wxtag-template">
                    <style>
                      .text {
                        font-size: 1rem;
                        font-weight: 500;
                        color: #064c36;
                      }
                      .copyWriting {
                        margin-top: 0.25rem;
                        font-size: 0.75rem;
                        font-weight: 400;
                        color: #178e69;
                      }
                      .circle {
                        margin-top: 0.25rem;
                        width: 2.5rem;
                        height: 1.1875rem;
                        background: #ffffff;
                        border-radius: 0.625rem;
                        font-size: 0.6875rem;
                        color: #1aaa7d;
                        font-weight: 500;
                        text-align: center;
                        line-height: 1.1875rem;
                      }
                    </style>
                     <div>
                        <div class="text">访客邀请</div>
                        <div class="copyWriting">无接触邀请</div>
                        <div class="circle">去邀请</div>
                      </div>
                  </script>
                </wx-open-launch-weapp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 入口 -->
    <!-- <van-button
      type="primary"
      style="margin-right: 6px"
      @click="navClick(rukou)"
      >7.13企业</van-button
    > -->
    <!-- <van-button
      type="primary"
      style="margin-right: 6px"
      @click="navClick(rukou2)"
      >笑了</van-button
    > -->
    <!-- <van-button
      type="primary"
      style="margin-right: 6px"
      @click="navClick(rukou3)"
      >测试3</van-button
    >
    <van-button
      type="primary"
      style="margin-right: 6px"
      @click="navClick(rukou4)"
      >青石桥</van-button
    >
    <van-button type="primary" @click="navClick(rukou5)">淦</van-button> -->
    <!-- 入口 -->
    <!-- 导航 新闻动态 -->
    <div class="box">
      <!-- 导航 -->
      <van-swipe class="nav" :loop="false" @change="swiperChange">
        <van-swipe-item>
          <div class="nav-item" style="display: inline-block">
            <img :src="`${publickPath}static/img/icons/` + 'anti-fraud-answer.png'" />
            <p class="name">反诈答题</p>
            <wx-open-launch-weapp style="baoliao" class="item-box" :id="miniId" :username="miniId"
              path="pages/authorization?fz=true&path=/pagesA/fz/index">
              <script type="text/wxtag-template">
                <style>
                  .text {
                    width: 23.115rem;
                    height: 5.3125rem;
                    background-color:red;
                  }
                </style>
                <div>
                  <div class="text">
                  </div>
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <div class="nav-item" style="display: inline-block">
            <img :src="
              `${publickPath}static/img/icons/` +
              'icon-management-service.png'
            " />
            <p class="name">物业报修</p>
            <wx-open-launch-weapp style="baoliao" class="item-box" :id="miniId" :username="miniId"
              path="pages/authorization?path=/pagesA/house/index">
              <script type="text/wxtag-template">
                <style>
                  .text {
                    width: 23.115rem;
                    height: 5.3125rem;
                    background-color:red;
                  }
                </style>
                <div>
                  <div class="text">
                  </div>
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <div class="nav-item" style="display: inline-block">
            <img :src="`${publickPath}static/img/icons/` + 'suggestions.png'" />
            <p class="name">意见建议</p>
            <wx-open-launch-weapp style="baoliao" class="item-box" :id="miniId" :username="miniId"
              path="pages/authorization?path=/pagesA/suggestions/index">
              <script type="text/wxtag-template">
                <style>
                  .text {
                    width: 23.115rem;
                    height: 5.3125rem;
                    background-color:red;
                  }
                </style>
                <div>
                  <div class="text">
                  </div>
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <div class="nav-item" style="display: inline-block">
            <img :src="`${publickPath}static/img/icons/` + 'payment.png'" />
            <p class="name">账单缴费</p>
            <wx-open-launch-weapp style="baoliao" class="item-box" :id="miniId" :username="miniId"
              path="pages/authorization?path=/pagesA/bill/payment">
              <script type="text/wxtag-template">
                <style>
                  .text {
                    width: 23.115rem;
                    height: 5.3125rem;
                    background-color:red;
                  }
                </style>
                <div>
                  <div class="text">
                  </div>
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <div class="nav-item" @click="navClick(nav)" v-for="(nav, i) in navList1" :key="i"
            style="display: inline-block">
            <div v-if="nav.name == '中医健康'" class="hot">
              <img :src="`${publickPath}static/img/icons/hot.png`" />
            </div>
            <img :src="`${publickPath}static/img/icons/` + nav.icon" />
            <p class="name">{{ nav.name }}</p>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="nav-item" @click="navClick(nav)" v-for="(nav, i) in navList2" :key="i"
            style="display: inline-block">
            <img :src="`${publickPath}static/img/icons/` + nav.icon" />
            <p class="name">{{ nav.name }}</p>
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <div v-for="(item, i) in navListIndex" :key="i" class="custom-box"
              :class="{ customBoxActive: active == i }"></div>
          </div>
        </template>
      </van-swipe>
      <div class="line"></div>
      <div class="message" @click="message">
        <div class="text-img">
          <img :src="`${publickPath}static/img/photo/message.png`" />
        </div>
        <div>
          <van-swipe :autoplay="3000" vertical :show-indicators="false" style="height: 1.25rem;">
            <van-swipe-item v-for="(v, i) in newsList" :key="i">
              {{ v }}
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="img-arrow">
          <img :src="`${publickPath}static/img/photo/arrow-right.png`" />
        </div>
      </div>
    </div>
    <!-- 广告 -->
    <div class="box advertising">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img @click="advertisingClick" :src="`${publickPath}static/img/bdhd.jpg`" />
        </van-swipe-item>
        <van-swipe-item style="position: relative">
          <img :src="`${publickPath}static/img/antiFraud.png`" />
          <wx-open-launch-weapp style="baoliao" class="advertising-box" :id="miniId" :username="miniId"
            path="pages/authorization?fz=true&path=/pagesA/fz/index">
            <script type="text/wxtag-template">
              <style>
                .text {
                  width: 23.115rem;
                  height: 6.2813rem;
                  background-color:red;
                }
              </style>
              <div>
                <div class="text">
                </div>
              </div>
            </script>
          </wx-open-launch-weapp>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 聊一聊 -->
    <div class="box padding" v-if="bbsList && bbsList.length">
      <div class="box-title">
        <p class="name">聊一聊</p>
        <div class="more" @click="bbsclickMore()">更多</div>
      </div>
      <div class="bbs-list">
        <div class="bbs-item" @click="clickBbs(bbs)" v-for="(bbs, i) in bbsList" :key="i">
          <div class="info">
            <div class="user">
              <img v-if="bbs.userPO" :src="bbs.userPO.avatarImage" />
              <img v-else :src="`${publickPath}static/img/photo/comment.png`" />
              <span>{{ bbs.userPO ? bbs.userPO.nickName : 'userName' }}</span>
            </div>
            <div class="time">
              {{ bbs.topicPO.createTime }}
            </div>
          </div>
          <div class="title">
            {{ bbs.topicPO.title }}
          </div>
          <div class="content">
            <p class="van-ellipsis">{{ bbs.topicPO.content }}</p>
            <!-- <span>全文</span> -->
          </div>
          <div class="cover" v-if="bbs.imagePOS">
            <img :src="item.url" v-for="(item, i) in bbs.imagePOS" :key="i" />
            <!-- <img :src="`${publickPath}static/img/photo/comment.png`" /> -->
          </div>
          <div class="bottom">
            <div class="reply" @click.stop="goComment(bbs.topicPO.id)">
              <img :src="`${publickPath}static/img/icons/icon-reply.png`" />{{
                  bbs.topicPO.commentCount
              }}
            </div>
            <div class="give-like" @click.stop="giveLike(bbs, i)">
              <img :src="`${publickPath}static/img/icons/icon-give-like.png`" />{{ bbs.topicPO.likeCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <toast-modal ref="tostModal" @cancelZ="cancelZ"></toast-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import toastModal from '@/components/toastModal';
import {
  carouselList,
  sysNoticeList,
  securityPrecautionList,
  topicWeTalkList,
  topicAddLike,
} from '@utils/aRequest';
import api from '@api';
const prefix = '/mock';
import { Dialog } from 'vant';
import { mapState, mapGetters } from 'vuex';
// import uSwiper from "@/components/u-swiper.vue";
export default {
  name: 'Home',
  data() {
    return {
      // 入口
      // rukou: {
      //   id: 1,
      //   name: '社区',
      //   path: '',
      //   icon: 'community.png',
      //   jumpUrl: '/community',
      //   isJumpCheck: true,
      //   type: 2,
      // },
      // rukou2: {
      //   id: 2,
      //   name: "社区",
      //   path: "",
      //   icon: "community.png",
      //   jumpUrl: "/community",
      //   isJumpCheck: true,
      //   type: 2
      // },
      // rukou3: {
      //   id: 3,
      //   name: "社区",
      //   path: "",
      //   icon: "community.png",
      //   jumpUrl: "/community",
      //   isJumpCheck: true,
      //   type: 2
      // },
      // rukou4: {
      //   id: 4,
      //   name: "社区",
      //   path: "",
      //   icon: "community.png",
      //   jumpUrl: "/community",
      //   isJumpCheck: true,
      //   type: 2
      // },
      // rukou5: {
      //   id: 5,
      //   name: "社区",
      //   path: "",
      //   icon: "community.png",
      //   jumpUrl: "/community",
      //   isJumpCheck: true,
      //   type: 2
      // },
      // 入口
      current: 0,
      publickPath: process.env.BASE_URL,
      addRessValue: null, //当前用户选择的小区
      addRessOption: [], //存在选择的小区
      carouselImgList: [], // 轮播图
      navList: [
        {
          name: '政务服务',
          path: '',
          icon: 'icon-government-service.png',
          jumpUrl: '/government',
          isJumpCheck: true,
          type: 2,
        },
        {
          name: '生活服务通',
          path: '',
          icon: 'community.png',
          jumpUrl: '/community',
          isJumpCheck: true,
          type: 2,
        },
        {
          name: '中医健康',
          path: '',
          icon: 'health-preservation.png',
          jumpUrl: '',
        },
        // {
        //   name: "物业报修",
        //   path: "",
        //   icon: "icon-management-service.png",
        //   jumpUrl: "pages/home/repair/repair"
        // },
        // {
        //   name: "意见建议",
        //   path: "",
        //   icon: "suggestions.png",
        //   jumpUrl: "pages/home/repair/repair"
        // },
        // {
        //   name: "账单缴费",
        //   path: "",
        //   icon: "payment.png",
        //   jumpUrl: "pages/home/repair/repair"
        // },
        {
          name: '本地商家',
          path: '',
          icon: 'icon-local-merchants.png',
          jumpUrl: 'pages/home/shop/shop-list',
          isJumpCheck: true,
        },
        {
          name: '聊一聊',
          path: '',
          icon: 'icon-bbs.png',
          jumpUrl: 'pages/disconver/bbs/talk/index',
          isJumpCheck: true,
        },
        {
          name: '招聘信息',
          path: '',
          icon: 'icon-employment.png',
          jumpUrl: 'pages/disconver/bbs/employment/employment-list',
          isJumpCheck: true,
        },
        {
          name: '出租求租',
          path: '',
          icon: 'icon-rent.png',
          jumpUrl: 'pages/disconver/bbs/rent/rent-list',
          isJumpCheck: true,
        },
        {
          name: '好货抢购',
          path: '',
          icon: 'icon-good-shopping.png',
          jumpUrl: '/pages/disconver/bbs/secondary-market/secondary-list',
          isJumpCheck: true,
        },
        {
          name: '社区拼团',
          path: '',
          icon: 'icon-community-spell-group.png',
          jumpUrl: 'pages/home/group-booking/group-list',
          isJumpCheck: true,
        },
        {
          name: '治安播报',
          path: '',
          icon: 'icon-security-broadcast.png',
          jumpUrl: '/pages/home/security-report/security-report',
        },
      ],
      navList1: [],
      navList2: [],
      navListIndex: [0, 1],
      active: 0,
      // 公告
      dynamicList: [],
      //安全防范
      safetyList: [],
      //聊一聊
      bbsList: [],
      // 好用点点小程序id
      miniId: 'gh_67be8caedeb9',
      isJumpMini: true, // 如果不是紫晶商务城小区则跳转小程序
      zjZoneId: '2c9280827477949e01747c32106c0000',
      // 地址code
      cityShow: '',
      zone: '',
      // 折扣
      zk: 0,
      // 用户是否实名
      state: 0,
      // 是否加载
      showLoading: false,
      // 房屋状态
      fangweiStatus: 0,
      txmurl: 'pages/authorization',
      // 消息列表轮播
      newsList: [],
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(['returnRoleData']),
  },
  components: {
    toastModal,
  },
  // components: { uSwiper },
  created() {
    this.$wx.ready(() => {
      this.$wx.getLocation({
        type: 'wgs84',
        success: (res) => {
          console.log('getLocation', res);
          this.getDistrict(res.latitude, res.longitude);
        },
        fail: (err) => {
          console.log('err getLocation', err);
        },
      });
    });
    let roleData = this.returnRoleData;
    this.addRessOption = [];
    this.getCurrentDiscount();
    if (roleData) {
      // 判断小区
      if (roleData.xiaoqu.length > 0) {
        var xiaoquArr = roleData.xiaoqu.filter(
          (item) =>
            item.userZoneRelationPO.addrFlag === 1 &&
            item.userZoneRelationPO.status === 2
        );
        var xiaoquCheck = roleData.xiaoqu.filter(
          (item) =>
            item.userZoneRelationPO.status === 2 &&
            item.userZoneRelationPO.cancel === 0
        );
        var xiaoqu =
          xiaoquArr.length !== 0
            ? xiaoquArr[0]
            : xiaoquCheck.length !== 0
              ? xiaoquCheck[0]
              : null;
        if (xiaoqu) {
          if (!this.addRessValue) {
            this.addRessValue = xiaoqu.zonePO ? xiaoqu.zonePO.id : '';
            localStorage.setItem('zoneId', xiaoqu.zonePO.id);
            this.$bus.emit('changeZoneId', xiaoqu.zonePO.id);
            this.isJumpMini = !(xiaoqu.zonePO.id === this.zjZoneId);
            localStorage.setItem('zone', JSON.stringify(xiaoqu.zonePO));
            localStorage.setItem('secondCheck', xiaoqu.zonePO.secondCheck);
            localStorage.setItem(
              'groupBuyingCheck',
              xiaoqu.zonePO.groupBuyingCheck
            );
          }
        } else {
          this.addRessValue = 2;
          this.addRessOption = [
            {
              text: '中国',
              value: 2,
            },
          ];
          this.isShowRemind = true;
          localStorage.setItem('zoneId', '');
          this.$bus.emit('changeZoneId', '');
          this.isJumpMini = true;
          localStorage.setItem('zone', '');
          localStorage.setItem('secondCheck', 0);
          localStorage.setItem('groupBuyingCheck', 0);
        }
        // console.log(xiaoquCheck, "xiaoquCheck");
        xiaoquCheck.forEach((item) => {
          this.addRessOption.push({
            text: item.zonePO.name,
            value: item.zonePO.id,
            secondCheck: item.zonePO.secondCheck,
            groupBuyingCheck: item.zonePO.groupBuyingCheck,
            zonePO: item.zonePO,
          });
        });
        const result = [];
        const obj = [];
        this.addRessOption.forEach((item) => {
          if (obj.indexOf(item.value) == -1) {
            result.push(item);
            obj.push(item.value);
          }
        });
        this.addRessOption = result;
      } else if (roleData.danwei.length > 0) {
        // 添加单位列表
        var danweiArr = roleData.danwei.filter(
          (item) =>
            item.userZoneRelationPO.addrFlag === 1 &&
            item.userZoneRelationPO.status === 2
        );
        var danweiCheck = roleData.danwei.filter(
          (item) =>
            item.userZoneRelationPO.status === 2 &&
            item.userZoneRelationPO.cancel === 0
        );
        var danwei =
          danweiArr.length !== 0
            ? danweiArr[0]
            : danweiCheck.length !== 0
              ? danweiCheck[0]
              : null;

        if (danwei) {
          this.addRessValue = danwei.zonePO ? danwei.zonePO.id : '';
          localStorage.setItem('zoneId', danwei.zonePO.id);
          this.$bus.emit('changeZoneId', danwei.zonePO.id);
          this.isJumpMini = !(danwei.zonePO.id === this.zjZoneId);
          localStorage.setItem('zone', JSON.stringify(danwei.zonePO));
          localStorage.setItem('secondCheck', danwei.zonePO.secondCheck);
          localStorage.setItem(
            'groupBuyingCheck',
            danwei.zonePO.groupBuyingCheck
          );
        } else {
          this.addRessValue = 2;
          this.addRessOption = [
            {
              text: '中国',
              value: 2,
            },
          ];
          this.isShowRemind = true;
          localStorage.setItem('zoneId', '');
          this.$bus.emit('changeZoneId', '');
          this.isJumpMini = true;
          localStorage.setItem('zone', '');
          localStorage.setItem('secondCheck', 0);
          localStorage.setItem('groupBuyingCheck', 0);
        }
        danweiCheck.forEach((item) => {
          this.addRessOption.push({
            text: item.zonePO.name,
            value: item.zonePO.id,
            secondCheck: item.zonePO.secondCheck,
            groupBuyingCheck: item.zonePO.groupBuyingCheck,
            zonePO: item.zonePO,
          });
        });
        const result = [];
        const obj = [];
        this.addRessOption.forEach((item) => {
          if (obj.indexOf(item.value) == -1) {
            result.push(item);
            obj.push(item.value);
          }
        });
        this.addRessOption = result;
      } else {
        this.setInData();
      }
    }
    // 初始化数据
    this.getInitialization();
  },
  mounted() {
    this.isHaveTxm();
    this.setShowAll();
    this.checkType();
    this.checkUserState();
    this.getNewsList();
  },
  methods: {
    // 首页消息轮播
    getNewsList() {
      api
        .listNew({ siteId: this.addRessValue })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.newsList = res.data.map((v) => {
              return v.title;
            });
          } else {
            this.newsList = ['暂无消息'];
          }
        })
        .catch(() => { });
    },
    // 判断用户是否拥有通行码
    isHaveTxm() {
      let { danwei, xiaoqu } = this.returnRoleData;
      if (danwei && danwei.length > 0) {
        danwei = danwei.filter((v) => v.zonePO.checkStatus == 1);
      }
      if (xiaoqu && xiaoqu.length > 0) {
        xiaoqu = xiaoqu.filter((v) => v.zonePO.checkStatus == 1);
      }
      console.log(danwei, xiaoqu, 'this.returnRoleData11');
      let arr = [...danwei, ...xiaoqu];
      if (arr.length > 0) {
        this.txmurl = 'pages/authorization';
      } else {
        this.txmurl =
          'pages/authorization?path=my/apply/passageCode-zone&backHome=1';
      }
      localStorage.setItem('txmurl', this.txmurl);
      console.log(this.returnRoleData, 'this.returnRoleData');
      console.log(this.txmurl, 'this.txmurl');
    },
    // 判断是否为赣州小区
    checkType() {
      this.cityShow = localStorage.getItem('areaCity');
      this.zone = localStorage.getItem('zone')
        ? JSON.parse(localStorage.getItem('zone'))
        : '';
      if (
        (this.cityShow && this.cityShow.includes('赣州')) ||
        (this.zone && this.zone.addressCode.includes('3607'))
      ) {
        let navList = [];
        if (this.navList.length > 4) {
          navList = JSON.parse(JSON.stringify(this.navList));
          this.navList1 = navList.splice(0, 4);
          this.navList2 = navList;
        }
        console.log('this.navList2', this.navList2);
        console.log('this.navList1', this.navList1);
      } else {
        let navList = [];
        // console.log(this.navList, this.navList.length, 111);
        this.navList.forEach((v) => {
          if (v.name != '政务服务') {
            navList.push(v);
          }
        });
        // console.log("navList", navList);
        if (navList.length > 4) {
          this.navList1 = navList.splice(0, 4);
          this.navList2 = navList;
        }
        // console.log("this.navList2", this.navList2);
        // console.log("this.navList1", this.navList1);
      }
    },
    // 重新获取位置
    getLocationNow() {
      this.showLoading = true;
      this.$wx.ready(() => {
        this.$wx.getLocation({
          type: 'wgs84',
          success: (res) => {
            console.log('getLocation', res);
            this.getDistrict(res.latitude, res.longitude);
          },
          fail: (err) => {
            console.log('err getLocation', err);
          },
        });
      });
      let roleData = this.returnRoleData;
      this.addRessOption = [];
      if (roleData) {
        // 判断小区
        if (roleData.xiaoqu.length > 0) {
          var xiaoquArr = roleData.xiaoqu.filter(
            (item) =>
              item.userZoneRelationPO.addrFlag === 1 &&
              item.userZoneRelationPO.status === 2
          );
          var xiaoquCheck = roleData.xiaoqu.filter(
            (item) =>
              item.userZoneRelationPO.status === 2 &&
              item.userZoneRelationPO.cancel === 0
          );
          var xiaoqu =
            xiaoquArr.length !== 0
              ? xiaoquArr[0]
              : xiaoquCheck.length !== 0
                ? xiaoquCheck[0]
                : null;

          if (xiaoqu) {
            if (!this.addRessValue) {
              this.addRessValue = xiaoqu.zonePO ? xiaoqu.zonePO.id : '';
              localStorage.setItem('zoneId', xiaoqu.zonePO.id);
              this.$bus.emit('changeZoneId', xiaoqu.zonePO.id);
              this.isJumpMini = !(xiaoqu.zonePO.id === this.zjZoneId);
              localStorage.setItem('zone', JSON.stringify(xiaoqu.zonePO));
              localStorage.setItem('secondCheck', xiaoqu.zonePO.secondCheck);
              localStorage.setItem(
                'groupBuyingCheck',
                xiaoqu.zonePO.groupBuyingCheck
              );
            }
          } else {
            this.addRessValue = 2;
            this.isShowRemind = true;
            localStorage.setItem('zoneId', '');
            this.$bus.emit('changeZoneId', '');
            this.isJumpMini = true;
            localStorage.setItem('zone', '');
            localStorage.setItem('secondCheck', 0);
            localStorage.setItem('groupBuyingCheck', 0);
          }
          xiaoquCheck.forEach((item) => {
            this.addRessOption.push({
              text: item.zonePO.name,
              value: item.zonePO.id,
              secondCheck: item.zonePO.secondCheck,
              groupBuyingCheck: item.zonePO.groupBuyingCheck,
              zonePO: item.zonePO,
            });
          });
        } else if (roleData.danwei.length > 0) {
          // 添加单位列表
          var danweiArr = roleData.danwei.filter(
            (item) =>
              item.userZoneRelationPO.addrFlag === 1 &&
              item.userZoneRelationPO.status === 2
          );
          var danweiCheck = roleData.danwei.filter(
            (item) =>
              item.userZoneRelationPO.status === 2 &&
              item.userZoneRelationPO.cancel === 0
          );
          var danwei =
            danweiArr.length !== 0
              ? danweiArr[0]
              : danweiCheck.length !== 0
                ? danweiCheck[0]
                : null;
          if (danwei) {
            this.addRessValue = danwei.zonePO ? danwei.zonePO.id : '';
            localStorage.setItem('zoneId', danwei.zonePO.id);
            this.$bus.emit('changeZoneId', danwei.zonePO.id);
            this.isJumpMini = !(danwei.zonePO.id === this.zjZoneId);
            localStorage.setItem('zone', JSON.stringify(danwei.zonePO));
            localStorage.setItem('secondCheck', danwei.zonePO.secondCheck);
            localStorage.setItem(
              'groupBuyingCheck',
              danwei.zonePO.groupBuyingCheck
            );
          } else {
            this.addRessValue = 2;
            this.isShowRemind = true;
            localStorage.setItem('zoneId', '');
            this.$bus.emit('changeZoneId', '');
            this.isJumpMini = true;
            localStorage.setItem('zone', '');
            localStorage.setItem('secondCheck', 0);
            localStorage.setItem('groupBuyingCheck', 0);
          }
          danweiCheck.forEach((item) => {
            this.addRessOption.push({
              text: item.zonePO.name,
              value: item.zonePO.id,
              secondCheck: item.zonePO.secondCheck,
              groupBuyingCheck: item.zonePO.groupBuyingCheck,
              zonePO: item.zonePO,
            });
          });
        } else {
          this.setInData();
        }
      }
      // 初始化数据
      this.getInitialization();
      setTimeout(() => {
        this.showLoading = false;
      }, 1000);
    },
    // 获取定位详址
    getDistrict(lat, lon) {
      let data = {
        location: `${lat},${lon}`,
        key: 'ILYBZ-7ZMK2-RPMU3-CSPUT-62L5T-LNFX5',
        output: 'jsonp',
      };
      this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1`, data)
        .then((res) => {
          console.log('getLocationAddr', res);
          if (res.result) {
            // 获取当前定位保存区code
            localStorage.setItem('areaCode', res.result.ad_info.adcode);
            localStorage.setItem('areaCity', res.result.ad_info.city);
            localStorage.setItem('areaDistrict', res.result.ad_info.district);
          }
        })
        .catch((err) => {
          console.log('err getLocationAddr', err);
        });
    },
    setInData() {
      this.addRessValue = 1;
      let city = localStorage.getItem('areaCity');
      let district = localStorage.getItem('areaDistrict');
      this.addRessOption = [
        {
          text: city + '-' + district,
          value: 1,
        },
      ];
      console.log('this.addRessOption', this.addRessOption);
      localStorage.setItem('zoneId', '');
      this.$bus.emit('changeZoneId', '');
      this.isJumpMini = true;
      localStorage.setItem('zone', '');
      localStorage.setItem('secondCheck', '');
      localStorage.setItem('groupBuyingCheck', '');
    },
    // 地区切换
    addRessChange(value) {
      let da = this.addRessOption;
      da.forEach((item) => {
        if (item.value == value) {
          localStorage.setItem('zoneId', value);
          this.$bus.emit('changeZoneId', value);
          this.isJumpMini = !(value === this.zjZoneId);
          localStorage.setItem('zone', JSON.stringify(item.zonePO));
          localStorage.setItem('secondCheck', item.secondCheck);
          localStorage.setItem('groupBuyingCheck', item.groupBuyingCheck);
        }
      });
      //初始化数据
      this.getInitialization();
      this.checkType();
    },
    // 弹窗
    checkUserState() {
      let state = 0; // 默认没有实名验证过
      let roleData = JSON.parse(localStorage.getItem('roleData'));
      if (roleData) {
        if (roleData.userPO.phone) {
          if (roleData.userPO.rlsbStatus == 1) {
            if (roleData.xiaoqu.length > 0) {
              var xiaoquArr = roleData.xiaoqu.filter(
                (item) =>
                  item.userZoneRelationPO.addrFlag === 1 &&
                  item.userZoneRelationPO.status === 2
              ); // 已通过并设置为默认房屋的数据
              var xiaoquCheck = roleData.xiaoqu.filter(
                (item) =>
                  item.userZoneRelationPO.status === 2 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 审核通过并未注销的房屋
              var xiaoqu =
                xiaoquArr.length !== 0
                  ? xiaoquArr[0]
                  : xiaoquCheck.length !== 0
                    ? xiaoquCheck[0]
                    : null;
              var noCheck = roleData.xiaoqu.filter(
                (item) =>
                  item.userZoneRelationPO.status === 1 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 有房屋但未审核
              var noCompleteZone = roleData.xiaoqu.filter(
                (item) =>
                  item.userZoneRelationPO.status === 0 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 有房屋但未完善
              var noPassZone = roleData.xiaoqu.filter(
                (item) =>
                  item.userZoneRelationPO.status === 3 ||
                  item.userZoneRelationPO.cancel === 1
              ); // 有房但未通过或已注销
              console.log(
                roleData,
                xiaoquCheck,
                xiaoqu,
                noCheck,
                noCompleteZone,
                noPassZone,
                'noPassZone'
              );
              if (xiaoqu) {
                state = 4; // 有住房且通过审核
              } else if (noCheck.length > 0) {
                state = 5; // 有住房但是未审核
              } else if (noCompleteZone.length > 0) {
                // 有房子但未完善
                state = 3;
              } else if (noPassZone.length > 0) {
                // 有房但未通过或已注销
                state = 3;
              }
            } else {
              state = 3; // 实名过没房子
            }
            this.fangweiStatus = state;
            console.log(this.fangweiStatus, 'fangweiStatus');
            if (state <= 3) {
              if (roleData.danwei.length > 0) {
                var danweiArr = roleData.danwei.filter(
                  (item) =>
                    item.userZoneRelationPO.addrFlag === 1 &&
                    item.userZoneRelationPO.status === 2
                ); // 已通过并设置为默认单位的数据
                var danweiCheck = roleData.danwei.filter(
                  (item) =>
                    item.userZoneRelationPO.status === 2 &&
                    item.userZoneRelationPO.cancel === 0
                ); // 审核通过并未注销的单位
                var danwei =
                  danweiArr.length !== 0
                    ? danweiArr[0]
                    : danweiCheck.length !== 0
                      ? danweiCheck[0]
                      : null;
                var noCheck = roleData.danwei.filter(
                  (item) =>
                    item.userZoneRelationPO.status === 1 &&
                    item.userZoneRelationPO.cancel === 0
                ); // 有单位但未审核
                var noCompleteZone = roleData.danwei.filter(
                  (item) =>
                    item.userZoneRelationPO.status === 0 &&
                    item.userZoneRelationPO.cancel === 0
                );
                // 有单位但未完善
                var noPassZone = roleData.danwei.filter(
                  (item) =>
                    item.userZoneRelationPO.status === 3 ||
                    item.userZoneRelationPO.cancel === 1
                ); // 有单位但未通过或已注销
                if (danwei) {
                  state = 4; // 有单位且通过审核
                } else if (noCheck.length > 0) {
                  state = 5; // 有单位但是未审核
                } else if (noCompleteZone.length > 0) {
                  // 有单位但未完善
                  state = 3;
                } else if (noPassZone.length > 0) {
                  // 有单位但未通过或已注销
                  state = 3;
                }
              } else {
                state = 3; // 实名过没单位
              }
            }
          } else {
            state = 2; // 未实名认证过
          }
        } else {
          state = 1; // 手机未验证过
        }
      }
      this.state = state;
      // if (state > 3) {
      //   return false;
      // } else if (state == 3) {
      //   this.$refs.tostModal.open(
      //     "通行码便于扫码通行，一次注册，永久便捷，请按登记流程规范填写，谢谢！",
      //     3
      //   );
      //   return false;
      // } else {
      //   this.$refs.tostModal.open();
      // }
    },
    cancelZ() {
      this.navCode = 'visit';
    },
    // 轮播切换
    onChange(index) {
      this.current = index;
    },
    navClick(nav) {
      // 入口
      // if (nav.id == 1) {
      //   nav.jumpUrl = nav.jumpUrl + `?siteId=123`;
      // }
      // if (nav.id == 2) {
      //   nav.jumpUrl = nav.jumpUrl + `?siteId=1480363404497424386`;
      // }
      // if (nav.id == 3) {
      //   nav.jumpUrl = nav.jumpUrl + `?siteId=1545701644967088130`;
      // }
      // if (nav.id == 4) {
      //   nav.jumpUrl = nav.jumpUrl + `?siteId=1382364542327091201`;
      // }
      // if (nav.id == 5) {
      //   nav.jumpUrl = nav.jumpUrl + `?siteId=1479794353743335425`;
      // }
      // 入口
      if (nav.name == '中医健康') {
        window.open(
          'https://mp.weixin.qq.com/mp/appmsgalbum?__biz=Mzk0NjM2MTIzNQ==&action=getalbum&album_id=2413875234761129986&scene=173&from_msgid=2247483858&from_itemidx=1&count=3&nolastread=1#wechat_redirect',
          '_blank'
        );
        return;
      }
      if (nav.name == '社区拼团') {
        window.open(
          'https://ktt.pinduoduo.com/login/explore?wx_share_scheme_sn=MRS719422082514310000016100000280463'
        );
        return;
      }
      if (nav.name == '生活服务通') {
        if (this.fangweiStatus <= 3) {
          console.log(this.fangweiStatus);
          this.$refs.tostModal.open(
            '暂未绑定房屋，绑定后即可开通所有服务。',
            2
          );
          return;
        } else {
          nav.jumpUrl = nav.jumpUrl + `?siteId=${this.addRessValue}`;
        }
      }
      if (nav.name == '聊一聊' && this.state <= 2) {
        this.$refs.tostModal.open();
        return;
      }
      // 是否无需判断直接跳转
      if (nav.isJumpCheck) {
        this.$globalFun.setUrlHeader(nav.jumpUrl, nav.type || 1);
      } else {
        //判断是否实名校验
        if (!this.$globalFun.checkUserState()) {
        } else {
          this.$globalFun.setUrlHeader(nav.jumpUrl);
        }
      }
    },
    // 安全防患更多按钮
    clickMore() {
      this.$globalFun.setUrlHeader('/pages/home/security-guard/index');
    },
    //聊一聊更多按钮
    bbsclickMore() {
      this.$globalFun.setUrlHeader('/pages/disconver/bbs/talk/index');
    },
    goComment(id) {
      this.$globalFun.setUrlHeader(
        '/pages/component/bbs/bbs-detail?topicId=' + id
      );
    },
    getInitialization() {
      var self = this;
      let zoneId = localStorage.getItem('zoneId');
      self.carouselImgList = [];
      self.dynamicList = [];
      self.safetyList = [];
      self.bbsList = [];
      //系统公告
      sysNoticeList({ pageNum: 0, pageSize: 5 }).then((data) => {
        if (data.code == 'SUCCESS') {
          if (data.data.content) {
            const arr = data.data.content;
            let arr1 = [];
            let arr2 = [];
            arr.forEach((i, k) => {
              if (k > 0 && k % 2 === 1) {
                arr2.push(i);
                arr1.push(arr2);
                arr2 = [];
              } else {
                arr2.push(i);
              }
            });
            if (arr2.length > 0) {
              arr1.push(arr2);
            }
            self.dynamicList = arr1;
          }
        }
      });
      //安全防范
      securityPrecautionList({ pageNum: 0, pageSize: 6 }).then((data) => {
        if (data.code == 'SUCCESS') {
          if (data.data.content) {
            data.data.content.forEach((item) => {
              item.securityPrecautionPO.createTime = item.securityPrecautionPO.createTime.substring(
                5,
                item.securityPrecautionPO.createTime.length
              );
            });
            self.safetyList = data.data.content;
          }
        }
      });
      if (this.addRessValue) {
        //轮播图
        api
          .carouselChartList({ siteId: this.addRessValue })
          .then((data) => {
            if (data.code == 'SUCCESS') {
              if (data.data.length) {
                this.carouselImgList = data.data.map((v) => {
                  return {
                    carouselUrl: v.carouselPicture,
                    jumpLink: v.jumpLink,
                  };
                });
              } else {
                this.carouselImgList = [
                  {
                    carouselUrl: `${this.publickPath}static/img/photo/huafeiBanner.png`,
                  },
                  {
                    carouselUrl: `${this.publickPath}static/img/photo/banner1.png`,
                  },
                  {
                    carouselUrl: `${this.publickPath}static/img/photo/banner2.png`,
                  },
                  {
                    carouselUrl: `${this.publickPath}static/img/photo/banner3.png`,
                  },
                ];
              }
            }
          })
          .catch(() => { });
        //聊一聊
        topicWeTalkList({ pageNum: 0, pageSize: 6, zoneId: zoneId }).then(
          (data) => {
            if (data.code == 'SUCCESS') {
              if (data.data.content) {
                self.bbsList = data.data.content;
              }
            }
          }
        );
      } else {
        this.carouselImgList = [
          {
            carouselUrl: `${this.publickPath}static/img/photo/huafeiBanner.png`,
          },
          { carouselUrl: `${this.publickPath}static/img/photo/banner1.png` },
          { carouselUrl: `${this.publickPath}static/img/photo/banner2.png` },
          { carouselUrl: `${this.publickPath}static/img/photo/banner3.png` },
        ];
      }
    },
    // 获取折扣
    getCurrentDiscount() {
      // console.log("折扣");
      api.getCurrentDiscount().then((res) => {
        if (res.response) {
          this.zk = res.response * 10;
        }
      });
    },
    // 顶部信息图标
    message() {
      // this.$globalFun.setUrlHeader("/pages/msg/index");
      this.$router.push({
        path: '/latest/news',
        query: { siteId: this.addRessValue },
      });
    },
    toXcx() {
      // alert('to小程序')
    },
    // 广告切换
    swiperChange(e) {
      this.active = e;
    },
    // 广告
    advertisingClick() {
      // console.log("广告");
      // 本地活动
      this.$globalFun.setUrlHeader('/pages/home/local-life/index');
    },
    adadvertisingClickMore() {
      this.$router.push('/cz');
    },
    // 安全播报
    clickSafety(id) {
      console.log('safety: ', id);
      this.$globalFun.setUrlHeader(
        '/pages/component/bbs/security-item-detail?id=' + id
      );
    },
    // 聊一聊内容
    clickBbs(bbs) {
      console.log('bbs: ', bbs);
    },
    // 是否展示全文按钮
    setShowAll() {
      let bbsItems = document.querySelectorAll('.bbs-item .content p');
      bbsItems.forEach((item, i) => {
        if (item.offsetWidth > 280) {
          this.bbsList[i].showAll = true;
        }
      });
    },
    // 点赞按钮
    giveLike(bbs, i) {
      var self = this;
      let title = '?topicId=' + bbs.topicPO.id;
      if (this.$globalFun.checkUserState()) {
        topicAddLike({}, title).then((data) => {
          if (data.code == 'SUCCESS') {
            self.bbsList[i].topicPO.likeCount++;
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  &.padding {
    padding: 0 1.066667rem
      /* 20/18.75 */
    ;
  }

  /deep/.van-cell__title {
    white-space: nowrap;
  }

  .box-title {
    height: 2.133333rem
      /* 40/18.75 */
    ;
    line-height: 2.08rem
      /* 39/18.75 */
    ;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-size: 0.906667rem
        /* 17/18.75 */
      ;
      font-family: PingFang SC;
      font-weight: 600;
      color: #252525;
      height: 0.96rem
        /* 18/18.75 */
      ;
      line-height: 0.96rem
        /* 18/18.75 */
      ;
      position: relative;
      padding-left: 0.64rem
        /* 12/18.75 */
      ;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: #2c72fd;
        border-radius: 0.106667rem
          /* 2/18.75 */
        ;
        width: 0.16rem
          /* 3/18.75 */
        ;
        height: 100%;
      }
    }

    .more {
      font-size: 0.8rem
        /* 15/18.75 */
      ;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b4b4b4;
    }
  }
}

.home {
  background: #f6f6f6;
}

.main {
  padding-bottom: 0.533333rem
    /* 10/18.75 */
  ;

  .select {
    display: flex;
    padding: 0 1.2625rem;
    align-items: center;
    height: 2.8125rem
      /* 40/18.75 */
    ;
    background-color: #ffffff;
    text-align: left;

    .img-position {
      width: 0.75rem;
      height: 0.9375rem;

      img {
        width: 100%;
        vertical-align: top;
      }
    }

    .img-arrow {
      font-size: 0.75rem;
      color: #1a79ff;

      .imgRight {
        width: 0.3125rem;

        img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }

    /deep/.van-dropdown-menu {
      flex: 1;
      height: 2.133333rem
        /* 40/18.75 */
      ;
      text-align: left;
      justify-content: flex-start;

      .van-dropdown-menu__title--active {
        color: @fcb;
      }

      .van-dropdown-item {
        z-index: 9999;
      }

      .van-dropdown-item__option--active {
        color: @fcb;
      }

      .van-dropdown-menu__bar {
        box-shadow: 0 0 0 0 #fff;
        height: 2.133333rem
          /* 40/18.75 */
        ;
        display: flex !important;
        justify-content: flex-start !important;

        .van-dropdown-menu__item {
          display: flex !important;
          justify-content: flex-start !important;
        }

        .van-dropdown-menu__title {
          display: inline-block !important;
        }
      }
    }
  }

  .home-swiper {
    width: 100%;
    padding: 0 0.625rem;
    overflow: hidden;

    .advertising-box {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 100;
      opacity: 0;
    }
  }

  .codes {
    overflow: hidden;
    padding: 0 0.625rem
      /* 20/18.75 */
    ;

    .title {
      position: relative;
      margin: 0.9375rem 0 0.7375rem 0;
      padding-left: 0.8rem;
      font-size: 0.9375rem;
      font-weight: 500;
      color: #333333;

      &::before {
        position: absolute;
        top: 0.25rem;
        left: 0;
        width: 0.1875rem;
        height: 0.9375rem;
        background: #1e7cff;
        border-radius: 0.125rem;
        content: '';
      }
    }

    .content {
      display: flex;

      &-left {
        width: 7rem;
        padding: 0.5rem;
        background: url(/static/img/photo/Charging-pile.png);
        background-size: 100% 100%;
        border-radius: 0.625rem;
        box-sizing: border-box;
      }

      &-right {
        margin-left: 0.625rem;
        flex: 1;
        box-sizing: border-box;

        .right-top {
          width: 100%;
          padding: 0.6875rem 0 0.4375rem 0.625rem;
          border-radius: 0.5rem;
          background: url(/static/img/photo/Operator.png) no-repeat;
          background-size: 4rem 3.6875rem;
          background-position: 6.875rem 1.25rem;
          background-color: #ffe3e3;
          color: #983434;
        }

        .right-bottom {
          display: flex;

          .bottomBox1 {
            width: 50%;
            height: 4.6875rem;
            margin-right: 0.3125rem;
            margin-top: 0.625rem;
            padding: 0.5rem 0 0 0.5rem;
            background: url(/static/img/photo/Pass-code.png);
            background-size: 100% 100%;
            border-radius: 0.3125rem;
          }

          .bottomBox2 {
            width: 50%;
            height: 4.6875rem;
            margin-left: 0.3125rem;
            margin-top: 0.625rem;
            padding: 0.5rem 0 0 0.5rem;
            background: url(/static/img/photo/Visitor-invitation.png);
            background-size: 100% 100%;
            border-radius: 0.3125rem;
          }
        }
      }
    }
  }
}

.nav {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  position: relative;

  .nav-item {
    position: relative;
    width: 25%;
    padding: 0.533333rem
      /* 10/18.75 */
      0;

    .hot {
      position: absolute;
      right: 1rem;
      top: 0.1875rem;

      img {
        width: 1.375rem;
        height: 0.7813rem;
      }
    }

    img {
      height: 1.493333rem
        /* 28/18.75 */
      ;
      margin: 0 auto;
      display: block;
    }

    .name {
      color: @fcb;
      font-size: 0.64rem
        /* 12/18.75 */
      ;
      margin-top: 0.64rem
        /* 12/18.75 */
      ;
      text-align: center;
    }

    .item-box {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  .custom-indicator {
    position: absolute;
    bottom: 0.7813rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    .custom-box {
      width: 0.25rem;
      height: 0.1875rem;
      margin-right: 0.1875rem;
      background: #e0e0e0;
      border-radius: 0.0938rem;
    }

    .customBoxActive {
      width: 0.8125rem;
      background: #1a79ff;
    }
  }
}

.line {
  margin: 0 1.5625rem;
  height: 0.0625rem;
  background: #f7f7f7;
}

.message {
  height: 3.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5625rem;

  .text-img {
    width: 1.9063rem;
    height: 2.0313rem;
    margin-right: 1.4063rem;

    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  .text {
    width: 10.2rem;
    font-size: 0.75rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .img-arrow {
    width: 0.3125rem;
    margin-left: 3rem;

    img {
      width: 100%;
      vertical-align: middle;
    }
  }
}

.dynamic {
  display: flex;
  padding: 0.533333rem
    /* 10/18.75 */
    0;
  width: calc(100% - 2.133333rem
      /* 40/18.75 */
    );
  margin: 0 auto;
  border-top: 0.053333rem
    /* 1/18.75 */
    solid #eeeeee;

  .dynamic-title {
    width: 2.133333rem
      /* 40/18.75 */
    ;
    font-size: 0.96rem
      /* 18/18.75 */
    ;
    line-height: 1.013333rem
      /* 19/18.75 */
    ;
    font-family: PingFang SC;
    font-weight: bold;

    p {
      &:first-child {
        color: #3a4653;
      }

      &:last-child {
        color: #2d73fd;
      }
    }
  }

  .van-swipe {
    height: 2.026667rem
      /* 38/18.75 */
    ;

    p {
      line-height: 1.013333rem
        /* 19/18.75 */
      ;
      width: 15.466667rem
        /* 290/18.75 */
      ;
      overflow: hidden;
      /*超出部分隐藏*/
      white-space: nowrap;
      /*禁止换行*/
      text-overflow: ellipsis;
      /*省略号*/
      color: @fcg;
      font-size: 0.693333rem
        /* 13/18.75 */
      ;
      padding-left: 1.706667rem
        /* 32/18.75 */
      ;
      position: relative;
      text-align: left;

      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0.373333rem
          /* 7/18.75 */
        ;
        left: 1.066667rem
          /* 20/18.75 */
        ;
        width: 0.266667rem
          /* 5/18.75 */
        ;
        height: 0.266667rem
          /* 5/18.75 */
        ;
        background: #2c72fd;
        border-radius: 50%;
      }
    }
  }
}

.advertising {
  margin-bottom: 1.5625rem;
  padding: 0.64rem
    /* 12/18.75 */
    1.066667rem
    /* 20/18.75 */
  ;
  height: 6.25rem;

  img {
    height: 100%;
    width: 100%;
  }

  .advertising-box {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 100;
    opacity: 0;
  }
}

.safety-list {
  .safety-item {
    display: flex;
    border-top: 0.053333rem
      /* 1/18.75 */
      solid #f4f4f4;
    padding: 0.746667rem
      /* 14/18.75 */
      0 0.586667rem
      /* 11/18.75 */
    ;

    .content {
      flex: 1;

      .title {
        font-size: 0.8rem
          /* 15/18.75 */
        ;
        font-family: PingFang SC;
        font-weight: 400;
        color: @fcb;
        line-height: 0.96rem
          /* 18/18.75 */
        ;
        text-align: left;
        height: 1.92rem
          /* 36/18.75 */
        ;
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.96rem
          /* 18/18.75 */
        ;

        .police {
          display: flex;
          align-items: center;

          img {
            width: 1.013333rem
              /* 19/18.75 */
            ;
            height: 1.013333rem
              /* 19/18.75 */
            ;
            border-radius: 50%;
            margin-right: 0.373333rem
              /* 7/18.75 */
            ;
          }

          span {
            font-size: 0.64rem
              /* 12/18.75 */
            ;
            font-family: PingFang SC;
            font-weight: 400;
            color: @fcb;
          }
        }

        .time {
          font-size: 0.64rem
            /* 12/18.75 */
          ;
          font-family: PingFang SC;
          font-weight: 400;
          color: #9e9e9e;
        }
      }
    }

    .cover {
      width: 5.333333rem
        /* 100/18.75 */
      ;
      // height: 3.84rem /* 72/18.75 */;
      border-radius: 0.266667rem
        /* 5/18.75 */
      ;
      overflow: hidden;
      margin-left: 1.173333rem
        /* 22/18.75 */
      ;

      img {
        width: 100%;
      }
    }
  }
}

.bbs-list {
  .bbs-item {
    border-top: 0.053333rem
      /* 1/18.75 */
      solid #f4f4f4;
    padding: 0.746667rem
      /* 14/18.75 */
      0;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.32rem
        /* 6/18.75 */
      ;

      .user {
        display: flex;
        align-items: center;

        &::before {
          display: none;
        }

        img {
          width: 1.76rem
            /* 33/18.75 */
          ;
          height: 1.76rem
            /* 33/18.75 */
          ;
          border-radius: 50%;
          margin-right: 0.533333rem
            /* 10/18.75 */
          ;
        }

        span {
          font-size: 0.746667rem
            /* 14/18.75 */
          ;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363635;
        }
      }

      .time {
        font-size: 0.64rem
          /* 12/18.75 */
        ;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9e9e9e;
      }
    }

    .title {
      font-size: 0.8rem
        /* 15/18.75 */
      ;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0a0a0a;
      text-align: left;
      line-height: 0.96rem
        /* 18/18.75 */
      ;
    }

    .content {
      line-height: 0.8rem
        /* 15/18.75 */
      ;
      font-size: 0.8rem
        /* 15/18.75 */
      ;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0a0a0a;
      display: flex;
      line-height: 21px;
      margin: 0.533333rem
        /* 10/18.75 */
        0 0.64rem
        /* 12/18.75 */
      ;

      p {
        max-width: calc(100% - 1.6rem
            /* 30/18.75 */
          );
        text-align: left;
        overflow: hidden;
        /*超出部分隐藏*/
        white-space: nowrap;
        /*禁止换行*/
        text-overflow: ellipsis;
        /*省略号*/
      }

      span {
        width: 1.6rem
          /* 30/18.75 */
        ;
        color: #2c72fd;
      }
    }

    .cover {
      text-align: left;

      img {
        width: 5.813333rem
          /* 109/18.75 */
        ;
        height: 5.813333rem
          /* 109/18.75 */
        ;
        border-radius: 0.266667rem
          /* 5/18.75 */
        ;
        margin-right: 0.266667rem
          /* 5/18.75 */
        ;
      }
    }

    .bottom {
      .df;
      justify-content: flex-end;
      margin-top: 0.533333rem
        /* 10/18.75 */
      ;

      >div {
        .df;
        font-size: 0.64rem
          /* 12/18.75 */
        ;
        line-height: 0.853333rem
          /* 16/18.75 */
        ;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9e9e9e;
        margin-left: 1.28rem
          /* 24/18.75 */
        ;
      }

      img {
        height: 0.96rem
          /* 18/18.75 */
        ;
        margin-right: 0.426667rem
          /* 8/18.75 */
        ;
      }
    }
  }
}
</style>
